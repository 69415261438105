<script>
  import HomeAnimationServers from './home-animation-servers.svelte';
</script>

<div class="container">
  <p>
    <span class="step">3</span>Confirm charge -
    <span class="crash">PROCESS CRASH</span>
  </p>
  <p class="crash"># Server CRASHES or loses power</p>
</div>
<HomeAnimationServers server="Offline" process="Inactive" />
