<script lang="ts">
  import { fade } from 'svelte/transition';

  import StepOne from './step-one.svelte';
  import StepTwo from './step-two.svelte';
  import StepThree from './step-three.svelte';
  import StepFour from './step-four.svelte';
  import StepFive from './step-five.svelte';
  import StepSix from './step-six.svelte';
  import StepSeven from './step-seven.svelte';
  import StepEight from './step-eight.svelte';

  export let step: number;

  type Steps =
    | typeof StepOne
    | typeof StepTwo
    | typeof StepThree
    | typeof StepFour
    | typeof StepFive
    | typeof StepSix
    | typeof StepSeven
    | typeof StepEight;

  const steps: Record<number, Steps> = {
    1: StepOne,
    2: StepTwo,
    3: StepThree,
    4: StepFour,
    5: StepFive,
    6: StepSix,
    7: StepSeven,
    8: StepEight,
  };
</script>

{#key step}
  <div in:fade={{ delay: 250, duration: 300 }}>
    <svelte:component this={steps[step]} />
  </div>
{/key}

<style lang="postcss">
  :global(.container) {
    @apply px-3 flex flex-col gap-1;
  }
  :global(.comment) {
    @apply text-blue-gray-500;
  }
  :global(.crash) {
    @apply text-red-300;
  }
  :global(.active) {
    @apply text-green-300;
  }
  :global(.step) {
    @apply inline-flex items-center justify-center w-6 h-6 rounded-full border mr-2;
  }
</style>
