<script>
  import HomeAnimationServers from './home-animation-servers.svelte';
</script>

<div class="container">
  <p>
    <span class="step">3</span>Confirm charge -
    <span class="active">ISSUE RESOLVED: COMPLETE</span>
  </p>
  <p># With <span class="text-purple">DURABLE EXECUTION</span>...</p>
  <ul class="list-disc list-inside">
    <li>you do not need to code for failure scenarios</li>
    <li>you can run a fct or wait for an API for a year</li>
    <li>you don't need a db, because variables are durable</li>
  </ul>
</div>
<HomeAnimationServers process="-" />
