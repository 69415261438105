<script lang="ts">
  export let process: 'Active' | 'Inactive' | '-' = 'Active';
  export let server: 'Live' | 'Offline' = 'Live';
</script>

<div class="flex flex-col gap-1 my-2 px-3">
  <div class="flex items-center gap-2">
    <div
      class="h-5 w-2 {server === 'Offline' ? 'bg-red-300' : 'bg-green-300'}"
    />
    <p class="flex gap-2">
      <span class="process" class:process-inactive={process !== 'Active'}
        >Process: {process}</span
      >
    </p>
  </div>
  <div class="flex items-center gap-2">
    <div
      class="h-5 w-2 {server === 'Offline' ? 'bg-red-300' : 'bg-green-300'}"
    />
    <p class="flex gap-2">
      <span class="server" class:server-offline={server === 'Offline'}
        >Server: {server}</span
      >
    </p>
  </div>
</div>

<style lang="postcss">
  .process,
  .server {
    @apply text-green-300;
  }
  .process-inactive,
  .server-offline {
    @apply text-red-300;
  }
</style>
