<script>
  import HomeAnimationServers from './home-animation-servers.svelte';
</script>

<div class="container">
  <p>
    <span class="step">3</span>Confirm charge -
    <span class="active">ISSUE RESOLVED: COMPLETE</span>
  </p>
  <p>
    # Your program is rehydrated on another server in the exact state at time of
    failure. Response comes (seconds or days later) and the function returns
  </p>
</div>
<HomeAnimationServers server="Offline" process="Inactive" />
<HomeAnimationServers process="-" />
