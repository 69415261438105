<script lang="ts">
  import CodeFade from './code-fade.svelte';

  export let step: number;
</script>

<div class="code">
  <div>
    <CodeFade active={step === 1}>
      <span class="def">def</span>
      <span class="method">process_order</span>(<span class="var">order</span>):
    </CodeFade>
    <div class="pl-6">
      <CodeFade active={step === 2}>
        <span class="var">check_fraud</span>(<span class="var">order</span
        >.<span class="property">order_id</span>,
        <span class="var">order</span>.<span class="property">payment_info</span
        >)
      </CodeFade>
      <CodeFade active={step === 3}>
        <span class="var">prepare_shipment</span>(<span class="var">order</span
        >)
      </CodeFade>
      <CodeFade
        active={[4, 5, 6, 7].includes(step)}
        error={step === 5}
        success={step === 6 || step === 7}
      >
        <span class="var">charge_confirm</span> =
        <span class="var">charge</span>(<span class="var">order</span>.<span
          class="property">order_id</span
        >,
        <span class="var">order</span>.<span class="property">payment_info</span
        >)
      </CodeFade>
      <CodeFade active={step === 8}>
        <span class="var">shipment_confirmation</span> =
        <span class="var">ship</span>(<span class="var">order</span>)
      </CodeFade>
    </div>
  </div>
</div>

<style lang="postcss">
  .code {
    @apply flex flex-col justify-between p-4 pb-12 overflow-auto;
  }

  .def {
    @apply text-red-300;
  }
  .method {
    @apply text-yellow-300;
  }
  .var {
    @apply text-teal;
  }
  .property {
    @apply text-mint;
  }
</style>
