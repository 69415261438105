<script lang="ts">
  import { onDestroy, onMount } from 'svelte';
  import HomeAnimationCode from './home-animation-code.svelte';
  import HomeAnimationSteps from './home-animation-steps.svelte';
  import Icon from '../icon/icon.svelte';
  import ProgressBar from './progress-bar.svelte';

  let play = true;
  let step = 1;
  let stepInterval: ReturnType<typeof setInterval>;
  let second = 0;
  let progressInterval: ReturnType<typeof setInterval>;
  let initialInterval: ReturnType<typeof setInterval>;

  const clearAllIntervals = () => {
    clearInterval(stepInterval);
    clearInterval(initialInterval);
    clearProgressInterval();
  };

  const stopAndClear = () => {
    play = false;
    clearAllIntervals();
  };

  const stepForward = () => {
    if (step < 8) {
      step += 1;
    } else {
      step = 1;
    }
  };

  const onNext = () => {
    stopAndClear();
    stepForward();
  };

  const stepBack = () => {
    if (step > 1) {
      step -= 1;
    } else {
      step = 8;
    }
  };

  const onPrevious = () => {
    stopAndClear();
    stepBack();
  };

  const setProgressInterval = () => {
    progressInterval = setInterval(() => {
      second += 1;
    }, 1000);
  };

  const clearProgressInterval = () => {
    second = 0;
    clearInterval(progressInterval);
  };

  const setStepInterval = () => {
    stepInterval = setInterval(() => {
      clearProgressInterval();
      setProgressInterval();
      stepForward();
    }, 10000);
  };

  const onPlayPause = () => {
    if (play) {
      stopAndClear();
    } else {
      play = true;
      clearProgressInterval();
      setProgressInterval();
      setStepInterval();
    }
  };

  onMount(() => {
    setProgressInterval();
    initialInterval = setTimeout(() => {
      stepForward();
      clearInterval(initialInterval);
      clearProgressInterval();
      setProgressInterval();
      setStepInterval();
    }, 10000);
  });

  onDestroy(() => {
    clearAllIntervals();
  });
</script>

<div class="terminal-shell">
  <div class="terminal">
    <p
      class="border-b text-blue-gray-500 border-blue-gray-800 mb-2 px-3 py-1 flex justify-between"
    >
      WORKFLOW.PSEUDO
    </p>
    <HomeAnimationCode {step} />
    <div class="console">
      <div class="flex gap-8 mb-4 border-y border-blue-gray-800 px-3 py-2">
        CONSOLE
      </div>
      <HomeAnimationSteps {step} />
    </div>
    <div class="actions">
      <div class="flex gap-4 text-sm">
        <button on:click={onPrevious}><Icon name="arrow-left" /></button>
        <ProgressBar value={second}>
          <button class="hover:opacity-80" on:click={onPlayPause}
            ><Icon
              name={play ? 'pause' : 'play'}
              class="text-green-400"
            /></button
          >
        </ProgressBar>
        <button on:click={onNext}><Icon name="arrow-right" /></button>
      </div>
    </div>
  </div>
</div>

<style lang="postcss">
  .terminal-shell {
    @apply py-6 pl-6 max-md:hidden opacity-90 bg-space-black font-mono rounded-l-xl h-full w-full;
  }
  .terminal {
    @apply flex flex-col bg-graphite h-full w-full rounded;
  }
  .console {
    @apply h-auto grow min-h-[240px] overflow-auto;
  }

  .actions {
    @apply px-3 py-4 flex items-center justify-start bg-graphite;

    & button {
      @apply text-blue-gray-300 hover:text-blue-gray-100;
    }
  }
</style>
