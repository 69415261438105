<script lang="ts">
  import { fade } from 'svelte/transition';

  export let active = false;
  export let error = false;
  export let success = false;
</script>

{#if active}
  <p
    class:highlight={active}
    class:error
    class:success
    in:fade={{ delay: 250, duration: 300 }}
  >
    <slot />
  </p>
{:else}
  <p>
    <slot />
  </p>
{/if}

<style lang="postcss">
  .highlight {
    @apply bg-ultraviolet transition-colors;
  }
  .error {
    @apply bg-red-200;
    :global(.var) {
      @apply text-red-900;
    }
    :global(.property) {
      @apply text-space-black;
    }
  }
  .success {
    @apply bg-green-200;
    :global(.var) {
      @apply text-green-900;
    }
    :global(.property) {
      @apply text-space-black;
    }
  }
</style>
