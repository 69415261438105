<script>
  import HomeAnimationServers from './home-animation-servers.svelte';
</script>

<div class="container">
  <p>
    <span class="step">2</span>Prepare the shipment - executes without issue
  </p>
</div>
<HomeAnimationServers />
